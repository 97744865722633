import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from "../../Redux/Reducers/UsersReducer";

function EditInformation() {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.UsersReducer?.users);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [dialCode, setDialCode] = useState('');
    const [contactNo, setContactNo] = useState('');

    useEffect(() => {
        dispatch(fetchUsers(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (user) {
            setValue('full_name', user?.full_name);
            setValue('email', user?.email);
            setValue('profile_bio', user?.profile_bio);
            setDialCode(user?.dialcode);
            setContactNo(user?.mobile_no);
        }
    }, [user, setValue]);

    const phoneInput = (value, data) => {
        const dialCode = `+${data?.dialCode}`;
        const mobileNumber = value?.substring(data?.dialCode?.length);
        setDialCode(dialCode);
        setContactNo(mobileNumber);
    };

    const onSubmit = (data) => {
        const formData = new FormData();
        if (data.full_name) formData.append('full_name', data.full_name);
        if (dialCode) formData.append('dialcode', dialCode);
        if (contactNo) formData.append('mobile_no', contactNo);
        if (data.interaction) formData.append('interaction', data.interaction.toString());
        if (data.id_verification) formData.append('id_verification', data.id_verification.toString());
        if (data.profile_bio) formData.append('profile_bio', data.profile_bio);
        if (dialCode) {
            data.dialcode = dialCode;
        }
        if (contactNo) {
            data.mobile_no = contactNo;
        }
        const config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASEURL}/users/${id}/`,
            headers: {
                'accept': 'application/json',
            },
            data: formData,
        };

        axios.request(config)
            .then((response) => {
                toast.success("Updated Successfully");
                if (response.status) {
                    navigate('/users-list');
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.response.data);
            });
    };

    return (
        <>
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold">Edit information</h6>
            </div>
            <div className="card-body">
                <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Full Name</label> <span className="text-danger">*</span>
                            <input
                                className="form-control"
                                type="text"
                                {...register('full_name', {
                                    required: 'First Name is required',
                                })}
                            />
                            {errors.full_name && <span className="text-danger">{errors.full_name.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Email</label> <span className="text-danger">*</span>
                            <input
                                className="form-control"
                                type="email"
                                disabled
                                id="email"
                                name="email"
                                {...register('email', {
                                    required: 'Email is required',
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                })}
                            />
                            {errors.email && <span className="text-danger">{errors.email.message}</span>}
                            {errors.email?.type === 'pattern' && <span className="text-danger">Invalid Email Address</span>}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="phoneid" className="form-label">Phone</label> <span className="text-danger">*</span>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                className: 'form-control w-100',
                                autoFocus: true,
                            }}
                            value={`${user.dialcode}${user.mobile_no}`}
                            onChange={phoneInput} />
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form-label">Profile Bio</label>
                            <textarea
                                placeholder='Enter Bio'
                                className="form-control"
                                name="profile_bio"
                                {...register('profile_bio')}
                            />
                        </div>
                    </div>

                    <div className="col-12 mt-4">
                        <button type="submit" className="btn btn-primary text-uppercase px-3">SAVE</button>
                        <Link to="/users-list" className="btn btn-primary text-uppercase px-3 mx-2">Cancel</Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditInformation;