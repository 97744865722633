import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PageHeader1 from '../../components/common/PageHeader1';
import Box from '@mui/material/Box';
import {
    DataGrid,
    GridToolbarQuickFilter,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress
} from '@mui/material';
import { fetchInterests } from '../../Redux/Reducers/InterestsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../Redux/Reducers/CsvInterestReducer';
import toast, { Toaster } from 'react-hot-toast';

function InterestList() {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const interests = useSelector(state => state?.InterestsReducer?.interests);
    const loading = useSelector(state => state?.InterestsReducer?.loading);
    const uploadStatus = useSelector((state) => state.CsvInterestReducer.uploadStatus);
    const error = useSelector((state) => state.CsvInterestReducer.error);

    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [''],
    });
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    useEffect(() => {
        dispatch(fetchInterests());
    }, [dispatch]);

    function deleteTeamMember(idsArray) {
        axios.delete(`${process.env.REACT_APP_BASEURL}/interests/bulk_delete/`, {
            data: { ids: idsArray }
        })
            .then(response => {
                dispatch(fetchInterests());
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleClickOpen = (interestId) => {
        setSelectedUserId(interestId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        if (selectedUserId && selectedUserId.length > 0) {
            const interestIds = selectedUserId?.map(interest => interest._id);
            deleteTeamMember(interestIds);
        }
        setOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 200 },
        { field: 'category', headerName: 'Category', width: 250 },
        { field: 'interest', headerName: 'Interest', width: 550 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: ({ row }) => (
                <div>
                    <IconButton component={Link} to={process.env.PUBLIC_URL + `/interest-edit/${row?._id}`}>
                        <i className="icofont-edit text-danger"></i>
                    </IconButton>
                    <IconButton component="button"
                        onClick={() => {
                            handleClickOpen(row.interests);
                        }}>
                        <i className="icofont-ui-delete text-danger"></i>
                    </IconButton>
                </div >
            ),
        },
    ];

    const CustomToolbar = ({ onImport }) => (
        <div style={{ display: 'flex', gap: '1rem', padding: '0.5rem' }}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />

            {uploadStatus === "loading" ?
                <CircularProgress style={{ fontSize: "5px" }} /> :
                <Button onClick={onImport}>
                    Import
                </Button>}
        </div >
    );

    const handleImport = useCallback(() => {
        fileInputRef.current.click();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            dispatch(uploadFile(file));
        }
    };

    useEffect(() => {
        if (uploadStatus === 'failed' && error) {
            toast.error(error);
        }
        if (uploadStatus === 'succeeded') {
            dispatch(fetchInterests());
        }
    }, [uploadStatus, error]);

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={true}
                duration="10000"
            />

            <div className="body d-flex py-1">
                <div className="container-xxl">
                    <PageHeader1 righttitle='Add Interest' link='/interest-add' routebutton={true} />
                    <div className="row g-0 mb-3">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Box sx={{ width: 1 }}>
                                                    <Box sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <input
                                                            type="file"
                                                            accept=".csv"
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                            onChange={handleFileChange}
                                                        />
                                                        {loading ? (
                                                            <CircularProgress />
                                                        ) : (

                                                            <DataGrid
                                                                columns={columns}
                                                                rows={interests?.results?.map((categoryItem, index) => ({
                                                                    interests: categoryItem.interests,
                                                                    _id: categoryItem?.category?.id,
                                                                    id: index + 1,
                                                                    category: categoryItem?.category?.name,
                                                                    interest: categoryItem?.interests.map(interest => interest.name).join(', ')
                                                                })) || []}
                                                                disableColumnFilter
                                                                slots={{ toolbar: CustomToolbar }}
                                                                filterModel={filterModel}
                                                                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                                slotProps={{ toolbar: { showQuickFilter: true, onImport: handleImport } }}
                                                                columnVisibilityModel={columnVisibilityModel}
                                                                onColumnVisibilityModelChange={(newModel) =>
                                                                    setColumnVisibilityModel(newModel)
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            handleDelete();
                        }} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

export default InterestList;