import React, { useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { fetchCategory } from '../../Redux/Reducers/CategoryReducer';
import { useDispatch, useSelector } from 'react-redux';

function BasicInformation() {
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    const category = useSelector((state) => state?.CategoryReducer?.category);

    useEffect(() => {
        dispatch(fetchCategory());
    }, [dispatch]);

    const categoryOptions = category?.results?.map(cat => ({
        value: cat._id,
        label: cat.name,
    }));

    const onSubmit = async (data) => {
        try {
            let jsonData = JSON.stringify(data.interests);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASEURL}/interests/add-interests/?category_id=${data.category}`,
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: jsonData
            };

            const response = await axios.request(config);
            console.log(response, "pgwrpgwrpgjwrp4209420942")
            toast.success("Added Successfully.");
            if (response?.status) {
                navigate("/interest-list");
            }
        } catch (error) {
            console.error('Team staff submission error: ', error);
            toast.error(error?.response?.data?.error);
        }
    };

    const handleInterestsChange = (selectedOptions) => {
        const interestsArray = selectedOptions ? selectedOptions?.map(option => option.value) : [];
        setValue("interests", interestsArray);
    };

    return (
        <>
            <div className="card-body">
                <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-6">
                        <label className="form-label">Category</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                            options={categoryOptions}
                            placeholder="Select Category"
                            {...register("category", {
                                required: "This field is required",
                            })}
                            onChange={(option) => setValue("category", option?.value)}
                        />
                        {errors.category && <span className="text-danger">{errors.category.message}</span>}
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Interests</label>
                            <span style={{ color: "red" }}>*</span>
                            <CreatableSelect
                                isMulti
                                noOptionsMessage={() => "Type to create"}
                                placeholder="Type to create"
                                onChange={handleInterestsChange}
                            />
                            {errors.interests && (
                                <span className="text-danger">
                                    {errors.interests.message}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="col-12 mt-4">
                        <button type="submit" className="btn btn-primary text-uppercase px-5">SAVE</button>
                        <Link to="/interest-list" type="button" className="btn btn-primary text-uppercase px-5 mx-2">CANCEL</Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default BasicInformation;