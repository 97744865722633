import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    category: [],
    status: 'idle',
    error: null,
};

// Create an async thunk for fetching data
export const fetchCategory = createAsyncThunk('category', async (id) => {
    const response = await fetch(`${process.env.REACT_APP_BASEURL}/categories/${id ?? ''}`);
    const data = await response.json();
    console.log(data, "catData")
    return data;
});

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategory.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                state.category = action.payload;
            })
            .addCase(fetchCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default categorySlice.reducer;