import React, { useRef, useState } from 'react';
import { ImEye } from "react-icons/im";
import { ImEyeBlocked } from "react-icons/im";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';

function SignIn() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dashboardLinkRef = useRef(null);

    const [showPassword, setShowPassword] = useState("");

    const onSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BASEURL}/admin/login/?email=${data.email}&password=${data.password}`)
            .then((response) => {
                if (response?.status == 200) {
                    localStorage.setItem("greet-admin-token", response.data.access_token);
                    localStorage.setItem("greet-admin-data", JSON.stringify(response.data.user));
                    dashboardLinkRef.current.click();
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.detail);
            });
    };

    const inputGroupStyle = {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    };

    const inputStyle = {
        paddingRight: '40px'
    };

    const iconStyle = {
        position: 'absolute',
        right: '10px',
        cursor: 'pointer'
    };

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={true}
                duration="10000"
            />
            <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100 " >
                <div className="w-100 p-3 p-md-5 card border-0 shadow-sm" style={{ maxwidth: "32rem" }}>
                    <form className="row g-1 p-3 p-md-4 mt-5" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12 text-center mb-0">
                            <h1>Sign in</h1>
                        </div>
                        <div className="col-12">
                            <div className="mb-2">
                                <label className="form-label">Email address</label>
                                <input {...register("email", { required: "Email is required." })}
                                    type="email"
                                    className="form-control form-control-lg"
                                    placeholder="name@example.com"
                                    onChange={(e) => e.target.value = e.target.value.toLowerCase()} />
                                {errors.email && <span className="text-danger">{errors.email.message}</span>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-2">
                                <div className="form-label">
                                    <span className="d-flex justify-content-between align-items-center">
                                        Password
                                        <Link className="text-secondary" to={process.env.PUBLIC_URL + "/reset-password"}>Forgot Password?</Link>
                                    </span>
                                </div>

                                <div style={inputGroupStyle}>
                                    <input
                                        {...register("password", { required: "Password is required." })}
                                        type={showPassword ? "text" : "password"}
                                        className="form-control form-control-lg"
                                        placeholder="Pa$$w0rd!"
                                        style={inputStyle} />
                                    <div style={iconStyle}>
                                        {showPassword ?
                                            <ImEyeBlocked onClick={() => setShowPassword(false)} /> :
                                            <ImEye onClick={() => setShowPassword(true)} />
                                        }
                                    </div>
                                </div>
                                {errors.password && <span className="text-danger">{errors.password.message}</span>}
                            </div>
                        </div>
                        <div className="col-12 text-center mt-4">
                            <button type="submit" className="btn btn-lg btn-block btn-light lift text-uppercase">SIGN IN</button>
                            <a ref={dashboardLinkRef} href="/dashboard" style={{ display: 'none' }}>Go to Dashboard</a>
                        </div>
                    </form>
                </div>
            </div>
        </>

    );
}

export default SignIn;