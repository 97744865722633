import React from 'react';
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import ProductList from './Products/ProductList';
import ProductEdit from './Products/ProductEdit';
import ProductAdd from './Products/ProductAdd';
import Header from '../components/common/Header';
import CouponsList from './Coupons/CouponsList';
import CouponsAdd from './Coupons/CouponsAdd';
import CouponsEdit from './Coupons/CouponsEdit';
import Invoices from './Accounts/Invoices';
import Expense from './Accounts/Expense';
import Salaryslip from './Accounts/Salaryslip';
import Chat from './App/Chat';
import Calendar from './App/Calendar';
import StaterPage from './Stater Page/StaterPage';
import SimpleInvoice from '../components/Accounts/Invoice/SimpleInvoice';
import TeamStaffList from './Users/UsersList';
import TeamStaffAdd from './Users/UsersAdd';
import TeamStaffEdit from './Users/UsersEdit';
import ServicesAdd from './Services/ServicesAdd';
import ServicesEdit from './Services/ServicesEdit';
import ServicesList from './Services/ServicesList';
import AddFAQ from './FAQ/AddFAQ';
import EditFAQ from './FAQ/EditFAQ';
import FAQList from './FAQ/FAQList';
import HelpList from './Help/HelpList';
import InterestAdd from './Interest/InterestAdd';
import InterestEdit from './Interest/InterestEdit';
import InterestList from './Interest/InterestList';
import CategoryAdd from './Categories/CategoryAdd';
import CategoryEdit from './Categories/CategoryEdit';
import CategoryList from './Categories/CategoryList';
import HeatMapBox from './HeatMap/HeatMapBox';
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy';
import { TermsAndCondition } from './TermsAndConditions/TermsAndCondition';

function MainIndex(props) {
  const { activekey } = props;
  const userToken = localStorage.getItem("greet-admin-token");

  if (!userToken) {
    return <Navigate to={process.env.PUBLIC_URL + "/sign-in"} />;
  }

  return (
    <div className='main px-lg-4 px-md-4' >
      {activekey === "/chat" ? "" : <Header />}
      <div className="body d-flex py-3 ">
        <ReactRoutes>
          <Route exact path={process.env.PUBLIC_URL + "/"} element={<Dashboard />} />
          <Route exact path={process.env.PUBLIC_URL + "/dashboard"} element={<Dashboard />} />

          <Route exact path={process.env.PUBLIC_URL + '/product-list'} element={<ProductList />} />
          <Route exact path={process.env.PUBLIC_URL + '/product-edit/:id'} element={<ProductEdit />} />
          <Route exact path={process.env.PUBLIC_URL + '/product-Add'} element={<ProductAdd />} />

          <Route exact path={process.env.PUBLIC_URL + '/coupons-list'} element={<CouponsList />} />
          <Route exact path={process.env.PUBLIC_URL + '/coupons-add'} element={<CouponsAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/coupons-edit/:id'} element={<CouponsEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/invoices'} element={<Invoices />} />
          <Route exact path={process.env.PUBLIC_URL + '/simple-invoice'} element={<SimpleInvoice />} />

          <Route exact path={process.env.PUBLIC_URL + '/expense'} element={<Expense />} />
          <Route exact path={process.env.PUBLIC_URL + '/salaryslip'} element={<Salaryslip />} />
          <Route exact path={process.env.PUBLIC_URL + '/chat'} element={<Chat />} />
          <Route exact path={process.env.PUBLIC_URL + '/calendar'} element={<Calendar />} />

          <Route exact path={process.env.PUBLIC_URL + '/stater-page'} element={<StaterPage />} />

          <Route exact path={process.env.PUBLIC_URL + '/users-list'} element={<TeamStaffList />} />
          <Route exact path={process.env.PUBLIC_URL + '/users-add'} element={<TeamStaffAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/users-edit/:id'} element={<TeamStaffEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/interest-list'} element={<InterestList />} />
          <Route exact path={process.env.PUBLIC_URL + '/interest-add'} element={<InterestAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/interest-edit/:id'} element={<InterestEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/category-list'} element={<CategoryList />} />
          <Route exact path={process.env.PUBLIC_URL + '/category-add'} element={<CategoryAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/category-edit/:id'} element={<CategoryEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/service-list'} element={<ServicesList />} />
          <Route exact path={process.env.PUBLIC_URL + '/service-add'} element={<ServicesAdd />} />
          <Route exact path={process.env.PUBLIC_URL + '/service-edit/:id'} element={<ServicesEdit />} />

          <Route exact path={process.env.PUBLIC_URL + '/faq'} element={<FAQList />} />
          <Route exact path={process.env.PUBLIC_URL + '/faq-add'} element={<AddFAQ />} />
          <Route exact path={process.env.PUBLIC_URL + '/faq-edit/:id'} element={<EditFAQ />} />

          <Route exact path={process.env.PUBLIC_URL + '/help'} element={<HelpList />} />
          <Route exact path={process.env.PUBLIC_URL + '/heat-map'} element={<HeatMapBox />} />

          <Route exact path={process.env.PUBLIC_URL + '/privacy-policy'} element={<PrivacyPolicy />} />
          <Route exact path={process.env.PUBLIC_URL + '/terms-conditions'} element={<PrivacyPolicy />} />

        </ReactRoutes>
      </div>
    </div>
  );
}
export default MainIndex;