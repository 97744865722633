import React, { useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useForm, useFieldArray } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { fetchFAQ } from '../../Redux/Reducers/FAQReducer';
import { useDispatch, useSelector } from 'react-redux';

function FAQAdd() {
    const dispatch = useDispatch();
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            faqs: []
        }
    });
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: "faqs"
    });

    const faqs = useSelector((state) => state.FAQReducer.faq);

    useEffect(() => {
        dispatch(fetchFAQ());
    }, [dispatch]);

    useEffect(() => {
        if (faqs) {
            replace([...faqs]);
            append({ question: "", answer: "" });
        }
    }, [faqs, replace, append]);

    const deleteFAQ = async (id, index) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASEURL}/faq/faqs/${id}`, {
                headers: {
                    'accept': 'application/json'
                }
            });
            if (response.status === 200) {
                toast.success('FAQ deleted successfully!');
                remove(index);
            } else {
                toast.error('Failed to delete FAQ');
            }
        } catch (error) {
            console.error('Error deleting FAQ:', error);
            toast.error('Error deleting FAQ');
        }
    };

    const onSubmit = (data) => {
        let jsonData = Array.isArray(data.faqs) ? data.faqs : [];

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASEURL}/faq/faqs`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: jsonData
        };

        axios.request(config)
            .then((response) => {
                if (response?.status === 200) {
                    toast.success('Added Successfully!');
                } else {
                    toast.error('Something went wrong!');
                }
            })
            .catch((error) => {
                console.error('FAQ submission error: ', error);
                toast.error(error?.message);
            });
    };


    return (
        <div className="card-body">
            <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
                {fields.map((field, index) => (
                    <div className="row g-3 align-items-center" key={field.id}>
                        <div className="col-md-10">
                            <label className="form-label">FAQ {index + 1}</label>
                            <span style={{ color: "red" }}>*</span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Question'
                                {...register(`faqs.${index}.question`)}
                                defaultValue={field.question}
                            />
                            {errors?.faqs?.[index]?.question && <p style={{ color: "red" }}>{errors.faqs[index].question.message}</p>}
                            <br />
                            <textarea
                                className="form-control"
                                placeholder='Answer'
                                {...register(`faqs.${index}.answer`)}
                                defaultValue={field.answer}
                                style={{ height: '150px' }}
                            />
                            {errors?.faqs?.[index]?.answer && <p style={{ color: "red" }}>{errors.faqs[index].answer.message}</p>}
                        </div>

                        <div className="col-md-2 align-self-end">
                            {index === fields.length - 1 ? (
                                <button
                                    type="button"
                                    className="btn btn-primary w-100 text-uppercase px-3"
                                    style={{ lineHeight: '23px', marginTop: '5px' }}
                                    onClick={() => append({ question: "", answer: "" })}
                                >
                                    +
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-danger w-100 text-uppercase px-3"
                                    style={{ lineHeight: '23px', marginTop: '5px' }}
                                    onClick={() => {
                                        if (field._id) {
                                            deleteFAQ(field._id, index);
                                        } else {
                                            remove(index);
                                        }
                                    }}
                                >
                                    <b style={{ color: "#fff" }}>-</b>
                                </button>
                            )}
                        </div>
                    </div>
                ))}

                <div className="col-12 mt-4">
                    <button type="submit" className="btn btn-primary text-uppercase px-5">SAVE</button>
                    <Link to="/faq" type="button" className="btn btn-primary text-uppercase px-5 mx-2">CANCEL</Link>
                </div>
            </form>
        </div>
    );
}

export default FAQAdd;