import React, { useEffect, useState } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useLocation } from 'react-router';
import { fetchPrivacyContent, postPrivacyContent } from '../../Redux/Reducers/PrivacyReducer';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

export const PrivacyPolicy = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { content, status, error } = useSelector((state) => state.privacy);
    const [data, setData] = useState("");

    useEffect(() => {
        dispatch(fetchPrivacyContent());
    }, [dispatch, location]);

    useEffect(() => {
        if (content) {
            const privacyContent = location.pathname === '/privacy-policy' ? content[0]?.content : content[1]?.content;
            setData(privacyContent);
        }
    }, [content]);

    const handlePostPrivacy = (e) => {
        e.preventDefault()
        dispatch(postPrivacyContent({ type: `${location.pathname === '/privacy-policy' ? 'privacy' : 'terms'}`, content: data }));
        if (status === "succeeded") {
            toast.success("Added Successfully!")
        } else {
            toast.error(error)
        }
    };
    const handleTextChange = (event, editor) => {
        const text = editor?.getData();
        setData(text)
    };

    return (
        <>
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h3 className="mb-0 fw-bold">{location.pathname === "/privacy-policy" ? 'Add Privacy Policy' : 'Add Terms & Condition'}</h3>
            </div>
            <hr />
            <div className="card-body">
                <form>
                    <div className="row g-3 align-items-center">
                        <div className="col-md-12">
                            <CKEditor
                                editor={ClassicEditor}
                                data={data}
                                config={{
                                    toolbar: [
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "link",
                                        "bulletedList",
                                        "numberedList",
                                        "|",
                                        "blockQuote",
                                        "insertTable",
                                        "|",
                                    ],
                                    placeholder: 'Enter you text here...'
                                }}
                                onChange={handleTextChange}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="d-flex justify-content-end">
                        <button type="submit"
                            onClick={handlePostPrivacy}
                            className="btn btn-primary btn-set-task w-sm-100 text-uppercase px-5">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}