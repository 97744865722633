import { combineReducers } from "redux";
import Mainreducer from "./Mainreducer";
import ProductReducer from "./ProductReducer";
import CategoryReducer from "./CategoryReducer";
import SubCategoryReducer from "./SubCategoryReducer";
import AttributeReducer from "./AttributeReducer";
import TagReducer from "./TagReducer";
import CouponReducer from "./CouponReducer";
import BrandReducer from "./BrandReducer";
import InterestsReducer from "./InterestsReducer";
import ProfileReducer from "./ProfileReducer";
import ServiceReducer from "./ServiceReducer";
import CommissionReducer from "./CommissionReducer";
import FAQReducer from "./FAQReducer";
import UsersReducer from "./UsersReducer";
import CsvInterestReducer from "./CsvInterestReducer";
import privacyReducer from './PrivacyReducer';

export default combineReducers({
    Mainreducer,
    ProductReducer,
    CategoryReducer,
    SubCategoryReducer,
    AttributeReducer,
    TagReducer,
    CouponReducer,
    BrandReducer,
    InterestsReducer,
    ProfileReducer,
    ServiceReducer,
    CommissionReducer,
    FAQReducer,
    UsersReducer,
    CsvInterestReducer,
    privacy: privacyReducer
})