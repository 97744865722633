import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    content: null,
    status: 'idle',
    error: null,
};

// Create an async thunk for posting privacy content
export const postPrivacyContent = createAsyncThunk(
    'privacy/postPrivacyContent',
    async ({ type, content }, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASEURL}/privacy/?type=${type}&content=${encodeURIComponent(content)}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchPrivacyContent = createAsyncThunk(
    'privacy/fetchPrivacyContent',
    async (rejectWithValue) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASEURL}/privacy/`);
            if (!response.ok) {
                throw new Error('Failed to fetch privacy content');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const privacySlice = createSlice({
    name: 'privacy',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Post Privacy Content
            .addCase(postPrivacyContent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postPrivacyContent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.content = action.payload;
            })
            .addCase(postPrivacyContent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Fetch Privacy Content
            .addCase(fetchPrivacyContent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPrivacyContent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.content = action.payload;
            })
            .addCase(fetchPrivacyContent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default privacySlice.reducer;