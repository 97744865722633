import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function BasicInformation() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [dialCode, setDialCode] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [profilePicture, setProfilePicture] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        const formData = new FormData();

        formData.append('email', data.email);
        formData.append('full_name', data.full_name);
        formData.append('profile_bio', data.profile_bio);
        formData.append('dialcode', dialCode);
        formData.append('mobile_no', contactNo);

        if (profilePicture) {
            formData.append('profile_picture', profilePicture);
        }

        try {
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASEURL}/users/registerbyadmin/`,
                data: formData,
            };
            setLoading(true);
            const response = await axios.request(config);
            toast.success("Added Successfully.");
            if (response?.status) {
                navigate("/users-list");
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Team staff submission error: ', error);
            toast.error(error?.response?.data?.detail || "Something went wrong!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setProfilePicture(file);
    };

    const phoneInput = (value, data) => {
        const dialCode = `+${data?.dialCode}`;
        const mobileNumber = value?.substring(data?.dialCode?.length);
        setDialCode(dialCode);
        setContactNo(mobileNumber);
    };

    return (
        <>
            <div className="card-body">
                <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Full Name  <span className="text-danger">*</span> </label>
                            <input
                                className="form-control"
                                type="text"
                                name='full_name'
                                placeholder='Enter your name'
                                {...register('full_name', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.full_name && <span className="text-danger">{errors.full_name.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Email <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="email"
                                id="email"
                                name="email"
                                placeholder='Staff email'
                                {...register('email', {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                        message: "Invalid Email Address"
                                    },
                                    onChange: (e) => {
                                        e.target.value = e.target.value.toLowerCase();
                                    }
                                })}
                            />
                            {errors.email && <span className="text-danger">{errors.email.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <label htmlFor="phoneid" className="form-label">Phone</label>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                className: 'form-control w-100',
                                autoFocus: true,
                            }}
                            onChange={phoneInput} />
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Profile Picture</label>
                            <input
                                className="form-control"
                                type="file"
                                name='profile_picture'
                                onChange={handleFileChange}
                            />
                            {errors.profile_picture && <span className="text-danger">{errors.profile_picture.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form-label">Profile Bio</label>
                            <textarea
                                placeholder='Enter profile bio'
                                className="form-control"
                                name="profile_bio"
                                {...register('profile_bio')}
                            />
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        {loading ?
                            <button type="submit" disabled className="btn btn-primary text-uppercase px-5">Loading...</button> :
                            <button type="submit" className="btn btn-primary text-uppercase px-5">SAVE</button>
                        }
                        <Link to="/users-list" type="button" className="btn btn-primary text-uppercase px-5 mx-2">CANCEL</Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default BasicInformation;